import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import HeroSection from "../components/HeroSection";
import Layout from "../containers/Layout";
import Union from "../images/404Page/union.svg";
import { graphql, navigate } from "gatsby";
import { useTranslation } from "react-i18next";
import { PAGE } from "../constants/pages";

interface NotFoundPageProps {
  data: any;
}

const Page404: React.FC<NotFoundPageProps> = ({ data }) => {
  const { t } = useTranslation();

  const pageData = data;
  const language = pageData?.pageContext?.i18n?.language;
  const cta = {
    text: t("Homepage"),
    route: "/"
  };
  const secondaryCta = {
    text: t("Go Back"),
    route: "/"
  };
  const notHacker = {
    text: t("Definitely not hacker files"),
    route: "https://jobs.lever.co/perpetualabs.com",
    icon: <Union />
  };

  return (
    <Layout page="404">
      <HeroSection
        title={t("There’s nothing to see here…")}
        description={t(
          "Sasquatch might be out there but this page doesn’t exist."
        )}
        cta={cta}
        secondaryCta={secondaryCta}
        thirdCta={notHacker}
        imageReplacement={
          language && language == "de" ? (
            <StaticImage
              className="w-3/4 lg:w-full"
              alt=""
              src={"../images/404Page/not-found-de.png"}
            />
          ) : (
            <StaticImage
              className="w-3/4 lg:w-full"
              alt=""
              src={"../images/404Page/not-found.png"}
            />
          )
        }
        bgGradient="from-indigo-3-light to-white bg-gradient-to-l"
        page={PAGE.PAGE_404}
      />
    </Layout>
  );
};

export default Page404;

export const data = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
